import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
  <Layout>
    <SEO title="Merci" />
    <div className="container">
        <h1>Merci</h1>
        <p>Nous revenons vers vous au plus vite !</p>
    </div>
  </Layout>
)

export default ThankYou